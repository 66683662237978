import { graphql } from 'gatsby';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import PageTemplate from '../components/PageTemplate/PageTemplate';
import BackToHomepageButton from '../components/WithQuery/BackToHomepageButton/BackToHomepageButton';
import { useHandleThankYouRedirect } from '../hooks/useHandleThankYouRedirect.hook';
import { PageComponent } from '../types/gatsby.types';
import joinClassNames from '../utils/className.utils';
import { sanitize } from '../utils/sanitize.utils';

export const query = graphql`
query ($id: String) {
  wpPage(id: { eq: $id }) {
    ...PageWithFeaturedImageFragment
  }
}
`;

type CenteredPageProps = {
  data: any,
}

const CenteredPage: PageComponent<CenteredPageProps> = props => {
  const {
    data: {
      wpPage: { id, title, content, slug, isFrontPage, featuredImage, seo },
    },
  } = props;

  useHandleThankYouRedirect();

  return <Observer children={() => (
    <PageTemplate
      {...props} // always include
      wpYoastSEO={seo}
      className={joinClassNames(slug)}
      pageSlug={slug}
      title={title}
      isFrontPage={isFrontPage}
      featuredMedia={featuredImage?.node}
    >

      <div className="page-section PageSectionEnquiry section-centered-page">
        <div className="container">
          <header className="section-page-header">
            <h1>{title}</h1>
            <div className="section-centered-page__header-content" dangerouslySetInnerHTML={{ __html: sanitize(content) }} />
            <BackToHomepageButton />
          </header>
        </div>
      </div>

    </PageTemplate>
  )} />
}

export default CenteredPage;